.fieldset {
    position: relative;
    width: 100%;
    border: 1px solid #3bced1;
    border-radius: 5px;
    padding: 25px 20px;
    box-sizing: border-box;
    margin-bottom: 20px;

    &__inner {
        display: flex;
        width: 100%;

        &-description {
            text-align: right;
            margin-right: 20px;
            width: 20%;

            &_item {
                font-size: 16px;
                font-weight: 700;

                &:first-of-type {
                    margin-top: 12px;
                    margin-bottom: 32px;
                }
            }
        }

        &-value {
            width: 80%;

            &_input {
                width: 100%;
                box-sizing: border-box;
                font-size: 14px;
                padding: 12px 15px;
                border-radius: 4px;
                border: 1px solid gray;
                outline: none;

                margin-bottom: 8px;

                &--error {
                    @extend .fieldset__inner-value_input;
                    border: 1px solid red;
                }
            }
        }

        &-close_btn {
            border: 1px solid rgb(96, 96, 96);
            transition: 0.2s linear;
            width: 35px;
            height: 35px;
            cursor: pointer;
            top: -15px;
            border: none;
            right: -15px;
            font-size: 25px;
            outline: none;
            border-radius: 50%;
            position: absolute;

            &:hover {
                color: red;
                box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            }
        }
    }
}
