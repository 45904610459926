.admin-search {
    display: flex;
    border-radius: 10px;
    margin-bottom: 20px;
    align-items: center;
    background-color: #fff;
    justify-content: space-between;
    padding: 7px 24px;

    &__input {
        width: 80%;
        padding: 0;
        border: none;
        outline: none;
        box-shadow: none;
        background-image: none;
        background-color: transparent;
        font-size: 16px;
        line-height: 22px;
    }

    &__btn {
        height: 48px;
        width: 125px;
        padding: 0;
        border: none;
        border-radius: 4px;
        text-align: center;
        background-color: #19b47b;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #fff;

        &:hover {
            cursor: pointer;
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: #33b378;
                color: #fff;
            }
        }
    }
}
