.promotion_form {
    width: 500px;

    padding: 20px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    max-height: 90vh;
    overflow-y: auto;
    box-sizing: border-box;

    &__title {
        margin: 0 0 30px 0;
        font-size: 30px;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .promotion_item {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            &_translation {
                margin-right: 30px;
                margin-bottom: 5px;
                align-items: center;
                display: flex;
                justify-content: space-between;

                &_text {
                    margin-right: 10px;
                }

                &-error {
                    margin: 0;
                    width: 100%;
                    text-align: left;
                    color: red;
                    position: absolute;
                    bottom: -12px;
                    left: 30px;
                }
            }

            &-error {
                margin: 0;
                width: 100%;
                color: red;
                text-align: left;
                position: absolute;
                bottom: -16px;
                left: 0;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
            &_textarea {
                width: 300px;
                height: 50px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid gray;
                resize: none;

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }
            &_input {
                width: 300px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid gray;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }
            &_select {
                &_type {
                    width: 332px;
                    margin-bottom: 8px;
                    font-size: 14px;
                    padding: 7.5px 15px;
                    border-radius: 4px;
                    border: 1px solid gray;
                    cursor: pointer;

                    &.error {
                        &:focus {
                            outline: none;
                            border: 1px solid red;
                        }
                    }

                    &:focus {
                        outline: none;
                        border: 1px solid #33b378;
                    }
                }
                &_date {
                    margin-bottom: 20px;
                    width: 332px;
                    font-size: 14px;
                    padding: 7.5px 15px;
                    border-radius: 4px;
                    border: 1px solid gray;
                    cursor: pointer;

                    &.error {
                        &:focus {
                            outline: none;
                            border: 1px solid red;
                        }
                    }

                    &:focus {
                        outline: none;
                        border: 1px solid #33b378;
                    }
                }
            }

            &_label {
                color: black;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &_image {
                outline: 0;
                border: none;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 10px;
                background-color: cornflowerblue;
                transition: 0.2s linear;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }

            &_btn {
                &--delete {
                    border: none;
                    color: white;
                    padding: 5px 12px;
                    border-radius: 5px;
                    transition: 0.2s linear;
                    background-color: gray;

                    &:hover {
                        background-color: rgb(214, 32, 32);
                        cursor: pointer;
                    }
                }
            }
        }

        .promotion_service {
            display: flex;
            align-items: center;
            .btn.btn--red {
                margin-right: 8px;
            }
        }
    }

    &-add-price {
        display: flex;
        align-items: center;
        flex-direction: column;

        &-info {
            color: black;
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        &-btn {
            background-color: #fff;
            border: none;
            cursor: pointer;
            margin-bottom: 10px;
        }
    }
}

.promotion__field {
    margin-bottom: 15px;
    border-width: 1px;
    padding: 5px;
    border-radius: 4px;
    width: 400px;
    border-color: gray;

    &-btn-delete {
        color: white;
        padding: 6px 12px;
        border: none;
        border-radius: 5px;
        transition: 0.2s linear;
        background-color: gray;
        margin-top: 15px;

        &:hover {
            background-color: rgb(214, 32, 32);
            cursor: pointer;
        }
    }
}
