@import "../../../assets/styles/main";
@keyframes anim {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

.support-chat {
    border-radius: 10px;
    padding: 22px 0 22px 0;
    background-color: $white;

    @include for-size(tablet-phone) {
        max-width: initial;
        width: 100%;
        margin: 0 0 15px 0;
        padding: 15px 0;
    }

    &__header {
        display: flex;
        align-items: center;
        padding-left: 18px;
        padding-bottom: 14px;
        border-bottom: 1px solid $neutral-light-grey-fdf;

        @include for-size(tablet-phone) {
            padding: 0 15px 15px 15px;
        }

        &_avatar {
            width: 58px;
            height: 58px;
            object-fit: cover;
            border-radius: 50%;
            position: relative;

            @include for-size(tablet-phone) {
                width: 45px;
                height: 45px;
            }

            &_online {
                content: "";
                right: 0;
                bottom: -1px;
                display: block;
                position: absolute;
                width: 15px;
                height: 15px;
                z-index: 100;
                border-radius: 50%;
                background-color: $main_green;

                @include for-size(tablet-phone) {
                    width: 10px;
                    height: 10px;
                }
            }
        }

        &_img {
            width: 58px;
            height: 58px;
            position: relative;
            margin-right: 20px;

            @include for-size(tablet-phone) {
                width: 45px;
                height: 45px;
            }

            &-active {
                width: 58px;
                height: 58px;
                position: relative;
                margin-right: 20px;

                @include for-size(tablet-phone) {
                    width: 45px;
                    height: 45px;
                    margin-right: 10px;
                }
            }
        }

        &_title {
            margin: 0 0 2px 0;
            color: $neutral-grey-343;
            @include text-size(normal, bold, 16px, 22px);

            @include for-size(tablet-phone) {
                @include text-size(normal, bold, 13px, 16px);
            }
        }

        &_status {
            text-align: left;
            color: $light_gray;
            @include text-size(normal, bold, 12px, 22px);

            @include for-size(tablet-phone) {
                @include text-size(normal, bold, 12px, 16px);
            }
        }
    }

    &_body {
        display: flex;
        padding: 25px 18px;
        flex-direction: column;
        height: 644px;
        overflow-y: auto;
        transition: all 1s linear;
        opacity: 1;

        @include for-size(tablet) {
            padding: 15px;
            height: 708px;
        }

        @include for-size(phone) {
            height: 500px;
            padding: 15px;
        }

        .chats__empty-result {
            height: 100%;
        }

        &::-webkit-scrollbar {
            width: 8px;
            background-color: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background: #33b378;
        }
        & > div:last-child {
            animation: anim 200ms;
        }
    }

    &_input {
        padding: 20px 20px 0px 28px;

        @include for-size(tablet-phone) {
            padding: 15px 15px 0px 15px;
        }

        &_list {
            &_item {
                display: flex;
                padding: 0 21px;
                align-items: center;
                background-color: $neutral-white-1f1;
                border-top: 1px solid $neutral-light-grey-fdf;
                height: 57px;

                @include for-size(tablet-phone) {
                    padding: 0 15px;
                }

                &:nth-child(3) {
                    padding: 0 21px 0 17px;

                    @include for-size(tablet-phone) {
                        padding: 0 15px 0 11px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    background-color: $white_background_def;

                    .support-chat_input_list_item_title {
                        color: $neutral-black-a32;
                    }
                    svg {
                        path {
                            fill: $main_green;
                        }
                    }
                }

                &:first-of-type {
                    border-radius: 6px 6px 0 0;
                }

                &:last-child {
                    border-radius: 0 0 10px 10px;
                }

                &_title {
                    color: $light_gray;
                    @include text-size(normal, normal, 15px, 18px);
                    padding-bottom: 5px;

                    @include for-size(tablet-phone) {
                        @include text-size(normal, normal, 13px, 16px);
                    }
                }

                &_icon {
                    margin-right: 20px;
                    svg {
                        path {
                            fill: $light_gray;
                        }
                    }
                }
            }
        }

        &_inner {
            display: flex;
            border-radius: 10px;
            padding: 20px 20px 21px 28px;
            background: $neutral-white-1f1;

            @include for-size(tablet-phone) {
                padding: 20px 20px 20px 20px;
            }

            &_finished {
                margin: 0 auto;
            }

            &-active {
                display: flex;
                padding: 20px 20px 21px 28px;
                background: $neutral-white-1f1;
                border-radius: 10px 10px 0 0;

                @include for-size(tablet-phone) {
                    padding: 20px 20px 20px 20px;
                }
            }
        }

        &_services {
            display: flex;
            align-items: center;
            margin-right: 20px;

            @include for-size(tablet-phone) {
                margin-right: 10px;
            }

            &-item {
                width: 16px;
                height: 16px;
                cursor: pointer;
                margin-right: 24px;

                @include for-size(tablet-phone) {
                    margin-right: 8px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &_body {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            &_input {
                width: 90%;
                height: 110%;
                border: none;
                outline: none;
                color: $neutral-black-a32;
                background: $neutral-white-1f1;

                @include for-size(tablet-phone) {
                    font-size: 13px;
                    line-height: 16px;
                }

                @media (max-width: 374px) {
                    font-size: 10px;
                }

                &::placeholder {
                    color: $neutral-black-a32;
                }
            }

            &_img {
                width: 16px;
                height: 16px;
                cursor: pointer;
                outline: none;
                border: none;

                &[disabled] {
                    cursor: not-allowed;
                    outline: none;
                    border: none;
                    width: 16px;
                    height: 16px;
                }
            }

            &_deleted {
                display: flex;
                width: 100%;
                justify-content: center;

                p {
                    font-weight: 600;
                    line-height: 16px;
                }
            }
        }
    }
}
