.services-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;

    &__content {
        display: block;
    }
}
