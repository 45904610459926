.blog_form {
    width: 500px;
    height: 85vh;
    padding: 20px 50px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow-y: scroll;

    @media screen and (max-height: 835px) {
        height: 95vh;
    }

    &__title {
        margin: 0 0 30px 0;
        font-size: 30px;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .blog__item {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            &_translation {
                margin-right: 30px;
                margin-bottom: 5px;

                &_text {
                    margin-right: 10px;
                }

                &-error {
                    margin: 0;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                    color: red;
                }
            }

            &:last-of-type {
                margin-bottom: 0;
            }

            &_label {
                color: black;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &_image {
                outline: 0;
                border: none;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 10px;
                background-color: cornflowerblue;
                transition: 0.2s linear;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }

            &_select {
                width: 100%;
                height: 54px;
                font-size: 14px;
                padding: 7.5px 15px;
                border-radius: 8px;
                border: 1px solid #dfdfdf;
                cursor: pointer;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &_text-editor {
                display: none;
                width: 392px;
            }

            &_text-editor-visible {
                display: block;
            }

            &_tab-wrapper {
                width: 100%;
                display: flex;
                justify-content: space-between;

                &-btn {
                    width: 33.3%;
                    padding: 5px 0;
                    cursor: pointer;
                }

                &-btn-active {
                    background-color: #2d9690;
                    color: #fff;
                }
            }
        }

        .blog__service {
            display: flex;
            margin: 10px 0 0 0;

            .btn.btn--red {
                margin-right: 8px;
            }
        }
    }
}
