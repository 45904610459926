@import "../../assets/styles/main";
.schedule_work {
    width: 100%;
    z-index: 10;
    border-radius: 10px;
    background-color: $white;

    &__content {
        display: block;
        padding: 28px 10px 31px 27px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 28px;
        border-bottom: 1px solid $neutral-light-grey-fdf;
    }

    &__title {
        margin: 0;
        @include text-size(normal, normal, 24px, 29px);
    }

    &__table {
        margin-bottom: 20px;
        padding: 15px 0 15px 0;
        border-bottom: 1px solid $neutral-light-grey-fdf;
    }

    &__btn {
        &--close {
            font-size: 40px;
            color: $neutral-light-grey-fdf;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;
                transform: scale(1.2);
            }
        }

        &--save {
            border: none;
            color: $white;
            font-size: 16px;
            padding: 12px 22px;
            margin-right: 12px;
            border-radius: 6px;
            background: $main_green;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;
                background: green;
            }
        }

        &--cancel {
            font-size: 16px;
            color: black;
            padding: 12px 33px;
            border-radius: 6px;
            background: transparent;
            border: 1px solid $neutral-light-grey-fdf;

            &:hover {
                cursor: pointer;
            }
        }
    }
}
