@import "../../assets/styles/main";

.location-select {
    width: 100%;
    text-align: left;
    color: $neutral-light-grey-fdf;

    &__label {
        margin: 0 0 6px 0;
        @include text-size(normal, normal, 12px, 16px);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__error {
        margin: 0;
        color: $main_red;

        @include text-size(normal, 600, 12px, 16px);
    }

    &__dropdown {
        padding: 0;
        margin-top: 0px;
        position: relative;
        border-radius: 8px;
        background-color: $white;
        border: 1px solid $neutral-light-grey-fdf;

        &_value {
            margin: 0;
        }

        &-disabled {
            @extend .location-select__dropdown;
            cursor: not-allowed;

            .location-select__dropdown_header {
                cursor: unset;
            }
        }

        &_circle {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 16px;
            min-height: 16px;
            max-height: 16px;
            max-width: 16px;
            border-radius: 50%;
            background-color: $main_green;
            border: 2px solid $white;

            &-border {
                position: absolute;
                width: 20px;
                height: 20px;
                border: 2px solid $main_green;
                background-color: none;
                border-radius: 50%;
            }

            &-disable {
                min-width: 16px;
                min-height: 16px;
                max-height: 16px;
                max-width: 16px;
                border-radius: 50%;
                background-color: none;
                border: 2px solid $neutral-light-grey-fdf;
            }
        }
        &-error {
            margin-top: 4px;
            border-radius: 8px;
            position: relative;
            background-color: $white;
            border: 1px solid $main_red;
        }

        &-confirmed {
            margin-top: 4px;
            border-radius: 8px;
            position: relative;
            background-color: $white;
            border: 1px solid $main_green;
        }
        &-open {
            margin-top: 0px;
            position: relative;
            border-radius: 4px 4px 0 0;
            background-color: $white;
            border: 1px solid $main_green;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            -webkit-animation: fadeInSelect 0.15s linear;
            animation: fadeInSelect 0.15s linear;
        }

        &-closed {
            margin-top: 4px;
            border-radius: 8px;
            position: relative;
            background-color: $white;
            border: 1px solid $neutral-light-grey-fdf;
        }

        &_icon {
            &-reverted {
                transform: rotate(180deg);
            }
        }

        &_inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:first-of-type {
                span {
                    display: none;
                }
            }

            &__info {
                padding: 5px 10px 0 0;
                cursor: pointer;

                svg {
                    width: 22px;
                    height: 22px;
                }
            }
        }

        &_header {
            padding: 0 15px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 51px;

            &_input {
                font-size: 15px;
                height: 51px;
                border: none;
                outline: none;
                color: $neutral-black-a32;
            }
        }

        &_body {
            display: none;
            position: relative;
            border-radius: 8px 8px 0 0;
        }

        &_body.open {
            left: -1px;
            z-index: 10;
            margin-top: 1px;

            display: block;
            position: absolute;
            box-sizing: border-box;

            max-height: 21vh;
            width: calc(100% + 2px);

            border-radius: 0px;
            border: 1px solid $neutral-light-grey-fdf;

            background-color: $white;
            overflow: auto;

            -webkit-animation: fadeInSelect 0.15s linear;
            animation: fadeInSelect 0.15s linear;

            &::-webkit-scrollbar {
                width: 2px;
                background-color: $white;
            }

            &::-webkit-scrollbar-thumb {
                background: $main_green;
            }
        }

        &_item {
            display: flex;
            align-items: center;
            padding: 16px 10px;

            &:first-of-type {
                &-border {
                    display: none;
                }
            }

            &:hover {
                cursor: pointer;
            }

            &-border {
                height: 2px;
                width: 323px;
                margin: 0 auto;
                display: block;
                background-color: $white;
            }

            &__label {
                margin: 0;
                color: $neutral-black-a32;
                padding-left: 16px;
                font-size: 14px;
                font-weight: 400;

                &-img {
                    width: 40px;
                    height: 22px;
                    display: flex;
                    align-items: center;
                }
            }

            input[type="radio"]:after {
                top: -1px;
                left: -2px;
                width: 16px;
                height: 16px;
                position: relative;
                background-color: $white;

                content: "";
                visibility: visible;
                display: inline-block;

                border-radius: 15px;
                border: 2px solid $neutral-light-grey-fdf;
            }

            input[type="radio"]:checked:after {
                top: -1px;
                left: -2px;
                width: 16px;
                height: 16px;
                position: relative;

                background-color: $main_green;
                box-shadow: 0 0 0 2px $main_green;

                border-radius: 18px;
                border: 2px solid $white;
            }
            &__radio {
                width: 18px;
                height: 18px;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@keyframes fadeInSelect {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@-webkit-keyframes fadeInSelect {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
