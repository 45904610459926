@import "../../../../assets/styles/main";

.promotions-history-item {
    &__active {
        color: $main_green;
    }

    &__inactive {
        color: $main_red;
    }

    &__options {
        button {
            color: white;
            margin-bottom: 10px;
            padding: 10px 12px;
            border: none;
            border-radius: 8px;
            transition: 0.2s linear;
            background-color: $main_green;

            &:hover {
                background-color: rgb(233, 229, 0);
                color: black;
                cursor: pointer;
            }

            &:disabled {
                background-color: gray;
            }
        }
    }
}
