.login-form {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 300px;

    &__field {
        margin: 0 0 25px 0;
        display: flex;
        flex-direction: column;
        position: relative;

        &-label {
            text-align: left;
            font-weight: 500;
            margin: 0 0 8px 0;
        }

        &-input {
            width: calc(100% - 17px);
            height: 40px;
            border-radius: 6px;
            border: 1px solid #e8e8e8;
            padding: 0 0 0 15px;

            &.error {
                &:focus {
                    outline: none;
                    border: 1px solid red;
                }
            }

            &:focus {
                outline: none;
                border: 1px solid #33b378;
            }
        }

        &-error {
            position: absolute;
            top: 0;
            right: 0;
            color: red;
            font-size: 14px;
        }
    }

    &__button {
        background: #33b378;
        border-radius: 6px;
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: #ffffff;
        border: none;
        outline: none;
        padding: 10px 0 10px 0;
        width: 100%;
        cursor: pointer;
        transition: all 0.2s linear;
        font-weight: 500;

        &:hover {
            opacity: 0.7;
        }

        &[disabled] {
            cursor: not-allowed;
            transition: all 0.2s linear;

            &:hover {
                background: #dfdfdf;
                color: #fff;
            }
        }
    }
}
