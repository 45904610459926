.promotion {
    height: 30px;
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;

    td {
        padding: 5px;
        text-align: center;
        border: 1px solid rgb(214, 213, 213);
        width: 15%;

        &:last-child {
            width: 200px;
            min-width: 200px;
            max-width: 200px;
            height: 100%;
        }
    }
    th {
        width: 25%;
    }
    &__title {
        color: white;
        padding: 10px;
        text-align: center;
        border: 1px solid #ddd;
        background-color: #2d9690;
    }

    tr:hover {
        background-color: rgba(71, 66, 71, 0.082);
    }

    &__btn {
        opacity: 0.8;
        color: black;
        padding: 7px 12px;
        border-radius: 4px;
        transition: 0.1s linear;
        border: 1px solid gray;
        background-color: rgb(145, 255, 0);

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}

.promotion__container {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
}
