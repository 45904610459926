.admin-sort {
    align-self: flex-start;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &__label {
        margin-right: 10px;
    }

    &__select {
        padding: 6px 12px;
        border: none;
        outline: none;
        border-radius: 6px;
        cursor: pointer;
    }
}
