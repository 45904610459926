.tag__form {
    width: 450px;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &__title {
        margin: 0 0 30px 0;
        font-size: 30px;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .tag__item {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            &_translation {
                margin-right: 30px;
                margin-bottom: 3px;
                align-items: center;
                display: flex;
                justify-content: space-between;

                &_text {
                    margin-right: 10px;
                }

                &-error {
                    width: 80%;
                    text-align: left;
                    margin: 0;
                    color: red;
                }
            }
            &-error {
                margin: 0;
                width: 100%;
                color: red;
                text-align: left;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
            &_textarea {
                width: 250px;
                height: 50px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid gray;
                resize: none;

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }
            &_input {
                width: 250px;
                font-size: 14px;
                padding: 10px 15px;
                margin-bottom: 3px;
                border-radius: 4px;
                border: 1px solid gray;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &_select {
                width: 282px;
                height: 45px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid gray;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &_label {
                color: black;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
            }

            &_image {
                outline: 0;
                border: none;
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 10px;
                background-color: cornflowerblue;
                transition: 0.2s linear;

                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                }
            }

            &_btn {
                &--delete {
                    border: none;
                    color: white;
                    padding: 5px 12px;
                    border-radius: 5px;
                    transition: 0.2s linear;
                    background-color: gray;

                    &:hover {
                        background-color: rgb(214, 32, 32);
                        cursor: pointer;
                    }
                }
            }
        }

        .tag__options {
            margin: 10px 0 0 0;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            &--cancel {
                border: none;
                color: white;
                opacity: 0.7;
                margin-right: 10px;
                padding: 10px 20px;
                border-radius: 3px;
                transition: 0.2s linear;
                background-color: blue;

                &:hover {
                    background-color: blue;
                    cursor: pointer;
                    opacity: 1;
                }
            }

            &--save {
                border: none;
                color: white;
                opacity: 0.7;
                padding: 10px 20px;
                border-radius: 3px;
                transition: 0.2s linear;
                background-color: green;

                &:hover {
                    background-color: green;
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }
}
