@import "../../../assets/styles/main";
.users-complaints {
    width: 100%;
    margin: 0 auto 20px auto;
    border-spacing: 1;
    border-collapse: collapse;
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);

    &__header {
        &_item {
            height: 70px;
            background: $main-green;

            cursor: pointer;
            &_title {
                opacity: 0.9;
                transition: 0.1s linear;
                color: $white;
                font-size: 15px;
                line-height: 1.2;
                font-weight: 500;

                &:hover {
                    color: azure;
                    svg {
                        opacity: 1;
                    }
                }

                &--active {
                    color: azure;
                    opacity: 1;
                    @extend.users-complaints__header_item_title;
                    .users-complaints__header_item_img {
                        svg {
                            opacity: 1;
                            transform: rotate(0deg);
                            transition: 0.1s linear;
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }
            &_img {
                margin-left: 5px;

                svg {
                    transition: 0.1s linear;
                    transform: rotate(180deg);
                    opacity: 0.3;
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }

    &_item {
        width: 100%;
        color: $dark_gray;
        line-height: 1.4;
        font-size: 14px;
        font-weight: 500;

        &_text {
            max-width: 400px;
            padding: 16px 5px;
        }
        &_link {
            text-decoration: none;
            padding-top: 16px;
            padding-bottom: 16px;

            &_post {
                @extend .users-complaints_item_link;
                color: $neutral-black-a32;

                &:hover {
                    color: $dark_gray;
                }
            }
            &_creator {
                @extend .users-complaints_item_link;
                color: $dark_gray;

                &:hover {
                    color: $main-green;
                }
            }
            &_user {
                @extend .users-complaints_item_link;
                color: #eb1010;
                &:hover {
                    color: $main_red;
                }
            }
        }

        &__options {
            padding: 4px 0;
            .btn.btn--green {
                margin-right: 8px;
            }
        }
        &:hover {
            background-color: #e8e5f6;
        }

        &:nth-child(even) {
            background-color: #f7f5ff;

            &:hover {
                background-color: #e8e5f6;
            }
        }
    }
}
