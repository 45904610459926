.complaints-container {
    width: 80%;
    margin: 50px auto;

    &__service {
        display: flex;
        width: 100%;
        justify-content: right;
        align-items: center;
        margin-top: 20px;
    }
}
