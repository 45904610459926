@import "../../assets/styles/main";
.back-button {
    padding: 15px 20px;
    background: $white;
    border-radius: 6px;
    font-family: "Roboto";
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s linear;
    color: $neutral-black-a32;

    &:hover {
        opacity: 0.7;
    }

    &__arrow {
        width: 6px;
        height: 6px;
        border-bottom: 2px solid $main_green;
        border-left: 2px solid $main_green;
        margin-right: 10px;
        transform: rotate(45deg);
    }

    @include text-size(normal, 400, 15px, 18px);
}
