@import "../../../assets/styles/main";

.moderator_form {
    width: 450px;
    border-radius: 10px;
    background-color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-height: 90vh;
    overflow-y: auto;

    &__title {
        font-size: 30px;
        margin: 0 0 25px 0;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__content {
        padding: 20px 50px;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .moderator__item {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            &:last-of-type {
                margin-bottom: 0;
            }

            &_input {
                width: 250px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid $neutral-light-grey-fdf;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }

            &_select {
                width: 100%;
                height: 54px;
                font-size: 14px;
                border-radius: 8px;
                padding: 7.5px 15px;
                border: 1px solid $neutral-light-grey-fdf;
                cursor: pointer;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }

            &-error {
                position: absolute;
                bottom: -16px;
                left: 0;
                color: $main_red;
                font-size: 14px;
            }

            &_label {
                color: $dark_gray;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 10px;
                display: flex;
                justify-content: flex-start;
            }
        }

        .moderator__service {
            display: flex;
            align-items: center;

            .btn.btn--red {
                margin-right: 8px;
            }
        }
    }
}
