.document_form {
    width: 650px;
    height: 80vh;
    padding: 20px 0 20px 0;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow-y: scroll;
    overflow-x: hidden;

    &__content {
        display: flex;
        flex-direction: column;
        padding: 0 20px 0 20px;
    }
    &__name {
        margin: 0 0 30px 0;
        text-align: center;
        font-size: 40px;
    }
    &__person {
        margin: 0 0 30px 0;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }
    &__preview {
        margin-bottom: 40px;
    }
    &__service {
        &--accept {
            margin-right: 10px;
            border: 1px solid green;
            background-color: transparent;
            width: 140px;
            transition: 0.2s linear;
            padding: 8px 20px;

            &:hover {
                cursor: pointer;
                color: white;
                background-color: #33b378;
            }
        }
        &--cancel {
            border: 1px solid green;
            background-color: transparent;
            width: 140px;
            transition: 0.2s linear;
            padding: 8px 20px;
            color: black;

            &:hover {
                color: white;
                background-color: rgb(214, 32, 32);
                cursor: pointer;
            }
        }
    }
}
