.flags {
    width: 100%;
    margin: 0 auto;
    border-spacing: 1;
    border-collapse: collapse;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);

    &__header {
        &_item {
            height: 60px;
            background: #33b378;
            &_title {
                width: 20%;
                font-size: 20px;
                color: #fff;
                line-height: 1.2;
                font-weight: 500;
            }
        }
    }

    &__content {
        &_item {
            color: gray;
            line-height: 1.4;
            font-size: 18px;
            font-weight: 500;

            &_text {
                padding-top: 16px;
                padding-bottom: 16px;
                &:first-of-type {
                    padding-left: 100px;
                    text-align: left;
                }
            }
            &_checkbox {
                border-radius: 8px;
                width: 20px;
                height: 20px;
                cursor: pointer;
            }

            &:hover {
                background-color: #e8e5f6;
            }

            &:nth-child(even) {
                background-color: #f7f5ff;

                &:hover {
                    background-color: #e8e5f6;
                }
            }
        }
    }
}
