@import "../../../../assets/styles/main";

.specialist-services__list_item {
    width: 500px;
    margin: 10px;
    padding: 20px 20px 20px 20px;
    background: $white;
    border-radius: 10px;

    &:last-of-type {
        margin-bottom: 0px;
    }

    &-cancelled {
        opacity: 0.7;
        margin-bottom: 20px;

        pointer-events: none;

        padding: 40px 45px 36px 45px;
        background: $white;
        border-radius: 10px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $neutral-light-grey-fdf;

        &_btn {
            cursor: pointer;
        }

        &_title {
            color: $neutral-black-a32;
            @include text-size(normal, 700, 24px, 30px);
        }
    }
    &__info {
        &_title {
            margin-top: 12px;
            padding: 9px 15px;
            border-radius: 4px;
            color: $neutral-black-a32;
            background: rgba(237, 238, 240, 0.37);
            @include text-size(normal, 700, 17px, 30px);
        }

        &_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 15px;
            border-bottom: 1px dashed $neutral-light-grey-fdf;
            word-break: break-word;

            &_description {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
            }

            &_value {
                @include text-size(normal, 400, 15px, 18px);
                color: $neutral-black-a32;
            }
        }
        &-btn {
            border: none;
            color: $white;
            font-size: 16px;
            padding: 12px 22px;
            margin-right: 12px;
            border-radius: 6px;
            background: $main_green;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;
                background: green;
            }
        }
    }
}
