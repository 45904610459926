@import "../../../assets/styles/main";

.new_user_form {
    width: 500px;
    max-height: 90vh;
    overflow-y: auto;
    border-radius: 10px;
    background-color: $white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &__title {
        font-size: 30px;
        margin: 0 0 25px 0;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__content {
        padding: 30px 60px;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .new_user__item {
            width: 100%;

            display: flex;
            position: relative;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            &_input {
                width: 100%;
                height: 54px;
                font-size: 14px;
                border-radius: 8px;
                padding: 10px 15px;
                box-sizing: border-box;
                border: 1px solid $neutral-light-grey-fdf;

                &.error {
                    border: 1px solid $main_red;
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &.confirmed {
                    border: 1px solid $main_green;
                    &:focus {
                        outline: none;
                        border: 1px solid $main_green;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }

            &_select {
                width: 100%;
                height: 54px;
                font-size: 14px;
                border-radius: 8px;
                padding: 7.5px 15px;
                border: 1px solid $neutral-light-grey-fdf;
                cursor: pointer;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }

            &-error {
                left: 7px;
                bottom: -19px;
                font-size: 12px;
                color: $main_red;
                position: absolute;
            }

            &_label {
                display: flex;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 5px;
                color: $neutral-black-a32;
                justify-content: flex-start;
            }

            &_btn {
                &--delete {
                    color: $white;
                    border: none;
                    opacity: 0.7;
                    padding: 8px 14px;
                    border-radius: 3px;
                    transition: all 0.2s linear;
                    background-color: $light_gray;

                    &.uploaded {
                        cursor: pointer;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    &.empty {
                        cursor: not-allowed;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .new_user__service {
        display: flex;
        margin-top: 20px;
        justify-content: center;

        .btn.btn--red {
            margin-right: 8px;
        }
    }
}
