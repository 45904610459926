.modal {
    top: 50%;
    transform: translate(0, -50%);

    width: 100%;
    height: 100%;
    

    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    background-color: rgba(0, 0, 0, 0.4);
    animation-name: appear;
    animation-duration: 300ms;

    &__close {
        font-size: 15px;
        top: 2%;
        right: 2%;
        height: 25px;
        width: 25px;
        position: absolute;
        background-color: white;

        border-radius: 50%;
        border: none;

        &:hover {
            background-color: red;
            color: white;
            cursor: pointer;
        }
    }

    &__content {
        border-radius: 6px;
        background: white;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        animation-duration: 0.5s;
        animation-name: slide-in;
    }
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slide-in {
    from {
        transform: translateY(-100px);
    }
    to {
        transform: translateY(0);
    }
}
