.admins {
    margin: 50px auto;
    width: 80%;
    display: flex;
    flex-direction: column;

    &__inner {
        margin-bottom: 20px;
        border-collapse: collapse;
        font-family: Arial, Helvetica, sans-serif;
        width: 100%;

        &__body {
            width: 100%;
        }

        td {
            padding: 5px;
            height: 80px;
            text-align: center;
            border: 1px solid rgb(214, 213, 213);
        }

        tr:hover {
            background-color: rgba(71, 66, 71, 0.082);
        }
    }

    &__title {
        padding: 10px;
        color: white;
        text-align: center;
        border: 1px solid #ddd;
        background-color: #19b47b;
    }

    &__button {
        width: 100%;
        opacity: 0.8;
        color: black;
        padding: 7px 12px;
        border-radius: 4px;
        transition: 0.1s linear;
        border: 1px solid gray;
        font-weight: 500;
        background-color: rgb(145, 255, 0);

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}
