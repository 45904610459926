.admins-item {
    &__services {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--edit {
            color: white;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;
            height: 50%;

            &:hover {
                background-color: rgb(233, 229, 0);
                color: black;
                cursor: pointer;
            }
        }

        &--delete {
            color: white;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;
            height: 50%;

            &:hover {
                background-color: rgb(214, 32, 32);
                cursor: pointer;
            }
        }
    }
    &--deleted {
        margin-left: 4px;
        padding: 6px 10px;
        color: white;
        background-color: red;
        border-radius: 5px;
    }
}
