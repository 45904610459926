@import "../../../assets/styles/main";
.specialist_service_form {
    margin-bottom: 30px;
    max-height: 90vh;
    overflow-y: auto;

    &__content {
        width: 80%;
        margin: 0 auto;
    }
    &__inner {
        padding: 20px 25px 0px 25px;
    }
    &_item {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 20px;
        position: relative;

        &__range {
            display: flex;
            &_input {
                width: 100%;
                font-size: 14px;
                padding: 15px 16px;
                border-radius: 4px;
                border: 1px solid #dfdfdf;
                @include text-size(normal, 600, 16px, 22px);
                color: #272a32;

                &:first-of-type {
                    margin-right: 20px;
                }
                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }
            }
        }
        &_input {
            box-sizing: border-box;
            width: 100%;
            font-size: 14px;
            padding: 15px 16px;
            border-radius: 4px;
            border: 1px solid #dfdfdf;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #272a32;
            &.error {
                &:focus {
                    outline: none;
                    border: 1px solid red;
                }
            }

            &:focus {
                outline: none;
                border: 1px solid #33b378;
            }
        }

        &-error {
            margin: 3px 0 0 0;
            color: red;
            font-size: 14px;
        }

        &_textarea {
            box-sizing: border-box;
            width: 100%;
            height: 100px;
            font-size: 14px;
            padding: 15px 16px;
            border-radius: 4px;
            border: 1px solid #dfdfdf;
            resize: none;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #272a32;

            &:focus {
                outline: none;
                border: 1px solid #33b378;
            }
            &.error {
                &:focus {
                    outline: none;
                    border: 1px solid red;
                }
            }
        }

        &_label {
            color: black;
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        &_image {
            outline: 0;
            border: none;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 10px;
            background-color: cornflowerblue;
            transition: 0.2s linear;

            &:hover {
                cursor: pointer;

                transform: scale(1.1);
            }
        }

        &_btn {
            &--delete {
                border: none;
                color: white;
                padding: 5px 12px;
                border-radius: 5px;
                transition: all 0.2s linear;
                background-color: #dfdfdf;
                opacity: 0.7;

                &.uploaded {
                    cursor: pointer;

                    &:hover {
                        opacity: 1;
                    }
                }

                &.empty {
                    cursor: not-allowed;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__service {
        display: flex;
        align-items: center;
        justify-content: center;

        .btn.btn--red {
            margin-right: 8px;
        }
    }
}
