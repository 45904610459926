@import "../../../assets/styles/main";

.nav {
    width: 250px;
    left: 0;
    top: 0;
    height: 100vh;
    background-color: #f3f3f3;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    position: sticky;
    overflow: auto;

    &__inner {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .logout__button {
        margin-top: 0px;
    }

    &__logo {
        cursor: pointer;
        padding-bottom: 40px;
    }
    &__content {
        margin-top: 30px;
        padding: 0px 25px 25px 25px;
    }

    &__links {
        margin: 0;
        padding: 0px 0;
        display: flex;
        flex-direction: column;
        list-style: none;
    }

    &__item {
        margin: 20px 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__dual-links-item,
    &__link {
        @include text-size(normal, 500, 18px, 22px);

        display: flex;
        justify-content: center;
        align-items: center;

        opacity: 0.6;
        color: black;
        cursor: pointer;
        font-family: Tahoma;
        text-decoration: none;
        transition: 0.2s linear;

        &-arrow {
            font-size: 35px;
            cursor: pointer;
            margin-left: 10px;
            padding-bottom: 7px;
            transition: 0.2s linear;

            &-active {
                @extend .nav__link-arrow;
                transform: rotate(-90deg);
            }
        }

        &--active {
            opacity: 1;
            color: $main-green;
            font-family: Tahoma;
            text-decoration: none;
            cursor: pointer;
            transition: 0.2s linear;

            @extend .nav__link;
            @include text-size(normal, 600, 18px, 22px);
        }

        &:hover {
            opacity: 1;
            color: $main-green;
        }
    }

    &__dual {
        position: relative;
        &-links {
            margin-top: 10px;
            &-item,
            &-item--active {
                display: block;
                padding: 5px 10px;
                margin-bottom: 10px;
                border-radius: 6px;
                background-color: #fff;
                box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.1);
            }
        }
    }
}

.logout__button {
    @include text-size(normal, 500, 18px, 22px);

    margin-top: 50px;
    border: none;
    outline: none;
    opacity: 0.6;
    color: black;
    font-size: 20px;
    font-weight: 500;
    font-family: Tahoma;
    text-decoration: none;
    transition: 0.2s linear;
    cursor: pointer;

    &--active {
        font-size: 22px;
        font-weight: 600;
        font-family: Tahoma;
        text-decoration: none;
        transition: 0.2s linear;
        color: $main-green;
    }

    &:hover {
        font-size: 21px;
        opacity: 1;
        color: red;
    }
}
