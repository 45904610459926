.specialist-feedbacks-item {
    &__options {
        display: flex;
        flex-direction: column;

        &--edit {
            color: white;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(233, 229, 0);
                color: black;
                cursor: pointer;
            }
        }
        &--visible,
        &--unvisible {
            color: white;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(20, 10, 10);
                cursor: pointer;
            }
        }
        &--unvisible {
            &:hover {
                background-color: #33b378;
                cursor: pointer;
            }
        }
        &--delete {
            color: white;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(214, 32, 32);
                cursor: pointer;
            }
        }
    }
}

.specialist-feedbacks-item {
    height: 30px;
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 20px;

    &__body {
        word-break: break-word;
    }

    thead {
        background-color: #2d9690;
    }

    td {
        padding: 5px;
        text-align: center;
        border: 1px solid rgb(214, 213, 213);
        width: 15%;

        &:last-child {
            width: 96.5%;
        }
    }
    th {
        width: 25%;
    }
    &__title {
        color: white;
        padding: 10px;
        text-align: center;
        border: 1px solid #ddd;
    }

    tr:hover {
        background-color: rgba(71, 66, 71, 0.082);
    }

    &__btn {
        opacity: 0.8;
        color: black;
        padding: 7px 12px;
        border-radius: 4px;
        transition: 0.1s linear;
        border: 1px solid gray;
        background-color: rgb(145, 255, 0);

        &:hover {
            cursor: pointer;
            opacity: 1;
        }
    }
}
