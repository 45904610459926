.specialist-feedbacks {
    width: 100%;
    margin: 0 auto;
    &__inner {
        margin: 0 auto 50px auto;
        width: 90%;
    }
    &__header {
        margin: 50px;
        font-size: 40px;

        &_id {
            font-weight: 600;
            color: black;
        }
    }
}
