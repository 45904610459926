.support-sort {
    &__label {
        font-size: 16px;
        margin-right: 10px;
    }

    &__select {
        padding: 12px 18px;
        outline: none;
        border-radius: 6px;
        cursor: pointer;
    }
}
