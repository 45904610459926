@import "../../../assets/styles/main";
.schedule_work {
    &__item {
        height: 38px;
        align-items: center;
        display: flex;
        margin-bottom: 12px;

        &:last-of-type {
            margin-bottom: 0px;
        }
    }

    &__time {
        align-items: center;
        display: flex;
        width: 60%;
    }

    &__day {
        border: none;
        font-size: 15px;
        width: 40%;
    }

    &__checkbox {
        margin-right: 9px;
    }

    &__time {
        display: flex;
        justify-content: flex-end;
        width: 80%;

        &--am {
            width: 90px;
            margin-right: 6px;
            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            padding: 8px 13px 7px 11px;
            background-color: transparent;
        }

        &--pm {
            width: 90px;
            border-radius: 4px;
            border: 1px solid $neutral-light-grey-fdf;
            padding: 8px 13px 7px 11px;
            background-color: transparent;
        }
    }

    &__weekend {
        color: gray;
        width: 25%;
        text-align: left;
        @include text-size(normal, normal, 15px, 18px);
    }
}
