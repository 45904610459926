@import "../../../assets/styles/main";

.accounts {
    margin: 50px auto;

    &__inner {
        margin-bottom: 20px;
        border-collapse: collapse;
        font-family: Arial, Helvetica, sans-serif;
        &__body {
            width: 100%;
        }
        td {
            padding: 5px;
            text-align: center;
            border: 1px solid rgb(214, 213, 213);
        }
    }
    &__header {
        &_item {
            height: 60px;
            background: $main-green;
            cursor: pointer;

            &_title {
                padding: 0 10px;
                opacity: 0.9;
                transition: 0.1s linear;
                color: $white;
                font-size: 15px;
                line-height: 1.2;
                font-weight: 500;

                .btn.btn--outlined {
                    height: 48px;
                    width: 120px;
                }

                &:hover {
                    color: azure;
                    svg {
                        opacity: 1;
                    }
                }

                &--active {
                    color: azure;
                    opacity: 1;
                    @extend.accounts__header_item_title;

                    .accounts__header_item_img {
                        svg {
                            opacity: 1;
                            transform: rotate(0deg);
                            transition: 0.1s linear;
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }
            &_img {
                margin-left: 5px;

                svg {
                    transition: 0.1s linear;
                    transform: rotate(180deg);
                    opacity: 0.3;
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }
}
