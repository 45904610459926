@import "../../../assets/styles/main";

.admin_form {
    width: 450px;
    max-height: 90vh;
    padding: 20px 50px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    overflow-y: auto;
    background-color: $white;

    &__title {
        font-size: 30px;
        margin: 0 0 25px 0;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .admin__item {
            width: 100%;
            margin-bottom: 20px;

            display: flex;
            align-items: center;
            flex-direction: column;
            position: relative;

            &:last-of-type {
                margin-bottom: 0;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input[type="number"] {
                -moz-appearance: textfield;
            }

            &_input {
                width: 100%;
                height: 54px;
                font-size: 14px;
                border-radius: 8px;
                padding: 10px 15px;
                box-sizing: border-box;
                border: 1px solid $neutral-light-grey-fdf;

                &.error {
                    border: 1px solid $main_red;
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &.confirmed {
                    border: 1px solid $main_green;
                    &:focus {
                        outline: none;
                        border: 1px solid $main_green;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }
            &_select {
                width: 100%;
                height: 54px;
                font-size: 14px;
                padding: 7.5px 15px;
                border-radius: 8px;
                border: 1px solid $neutral-light-grey-fdf;
                cursor: pointer;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid $main_red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid $main_green;
                }
            }
            &-error {
                left: 7px;
                bottom: -19px;
                font-size: 12px;
                color: $main_red;
                position: absolute;
            }

            &_label {
                display: flex;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 5px;
                color: $neutral-black-a32;
                justify-content: flex-start;
            }
        }

        .admin__service {
            display: flex;
            align-items: center;

            .btn.btn--red {
                margin-right: 8px;
            }
        }
    }
}
