.spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.spinner {
    margin: 2px;
    display: inline-block;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: 1s spin ease-in-out infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
