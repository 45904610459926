@import "../../../assets/styles/main";
.faq {
    width: 100%;
    margin: 0 auto 20px auto;
    border-spacing: 1;
    border-collapse: collapse;
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);

    &__header {
        &_item {
            height: 70px;
            background: $main-green;

            cursor: pointer;
            &_title {
                opacity: 0.9;
                transition: 0.1s linear;
                color: $white;
                font-size: 15px;
                line-height: 1.2;
                font-weight: 500;

                &:hover {
                    color: $white;
                    svg {
                        opacity: 1;
                    }
                }

                &--active {
                    color: $white;
                    opacity: 1;
                    @extend.faq__header_item_title;
                    .faq__header_item_img {
                        svg {
                            opacity: 1;
                            transform: rotate(0deg);
                            transition: 0.1s linear;
                            width: 17px;
                            height: 17px;
                        }
                    }
                }
            }
            &_img {
                margin-left: 5px;

                svg {
                    transition: 0.1s linear;
                    transform: rotate(180deg);
                    opacity: 0.3;
                    width: 17px;
                    height: 17px;
                }
            }
        }
    }

    &_item {
        width: 100%;
        color: $dark_gray;
        line-height: 1.4;
        font-size: 14px;
        font-weight: 500;

        &_text {
            word-wrap: break-word;
            color: $dark_gray;
            max-width: 400px;
            padding: 16px 10px;

            &-active {
                color: $white;
                border-radius: 4px;
                padding: 5px 10px;
                background-color: $main-green;
            }

            &-disabled {
                color: $white;
                border-radius: 4px;
                padding: 5px 10px;
                background-color: $dark_gray;
            }
        }
        &_link {
            text-decoration: none;
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &__options {
            width: 300px;
            padding: 16px 10px;

            .btn.btn--green {
                margin-bottom: 4px;
            }

            &-disabled {
                font-size: 15px;
                line-height: 22px;
                text-align: center;
                outline: none;
                width: 170px;
                height: 48px;
                cursor: pointer;
                color: #ffffff;
                border-radius: 4px;
                border: none;
                background-color: $dark_gray;
            }
        }
        &_no-exist {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            background-color: $white;
            margin-bottom: 20px;
            width: 100%;
            height: 500px;
            border-radius: 10px;
            &-image {
                padding: 0 0 16px;
            }
            &-text {
                @include text-size(normal, 500, 20px, 24px);

                color: $neutral-black-a32;
            }
        }
        &:hover {
            background-color: $main_background;
        }

        &:nth-child(even) {
            background-color: $white;

            &:hover {
                background-color: $main_background;
            }
        }
    }
}
