.specialist-feedback-edit {
    width: 500px;
    height: auto;
    padding: 20px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    &__title {
        margin: 0 0 30px 0;
        font-size: 30px;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        &__item {
            margin-bottom: 20px;

            &:last-of-type {
                margin-bottom: 0;
            }

            &_input {
                width: 250px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 4px;
                border: 1px solid gray;

                &.error {
                    margin-bottom: 5px;
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &_label {
                color: black;
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 10px;
                margin-right: 10px;
            }

            &-error {
                margin: 0;
                width: 100%;
                color: red;
                text-align: left;
            }
        }
    }
}

.specialist-feedback-edit__content__service {
    margin: 10px 0 0 0;

    &--cancel {
        border: none;
        color: white;
        opacity: 0.7;
        margin-right: 10px;
        padding: 10px 20px;
        border-radius: 3px;
        transition: 0.2s linear;
        background-color: blue;

        &:hover {
            background-color: blue;
            cursor: pointer;
            opacity: 1;
        }
    }

    &--save {
        border: none;
        color: white;
        opacity: 0.7;
        padding: 10px 20px;
        border-radius: 3px;
        transition: 0.2s linear;
        background-color: green;

        &:hover {
            background-color: green;
            cursor: pointer;
            opacity: 1;
        }
    }
}
