.specialist_form {
    width: 500px;
    border-radius: 10px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-height: 90vh;
    overflow-y: auto;

    &__title {
        font-size: 30px;
        margin: 0 0 25px 0;
        text-align: center;
        font-weight: normal;
        font-family: Tahoma;
    }

    &__content {
        padding: 30px 40px;
    }

    &__items {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .specialist__upload {
            &_default {
                margin: 0 auto 10px auto;
                width: 120px;
                height: 120px;
                border-radius: 100%;
                border: 6px solid #f8f8f8;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
            }
            &_preview {
                margin: 0 auto 10px auto;
                width: 120px;
                height: 116.61px;
                border-radius: 100%;
                border: 6px solid #f8f8f8;
                box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
                object-fit: cover;
            }
        }

        .specialist__item {
            width: 100%;

            display: flex;
            align-items: center;
            flex-direction: column;
            margin-bottom: 20px;
            position: relative;

            &:last-of-type {
                margin-bottom: 0;
            }

            &_input {
                width: 100%;
                box-sizing: border-box;
                height: 54px;
                font-size: 14px;
                padding: 10px 15px;
                border-radius: 8px;
                border: 1px solid #dfdfdf;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &_select {
                width: 100%;
                height: 54px;
                font-size: 14px;
                padding: 7.5px 15px;
                border-radius: 8px;
                border: 1px solid #dfdfdf;
                cursor: pointer;

                &.error {
                    &:focus {
                        outline: none;
                        border: 1px solid red;
                    }
                }

                &:focus {
                    outline: none;
                    border: 1px solid #33b378;
                }
            }

            &-error {
                position: absolute;
                bottom: -16px;
                left: 0;
                color: red;
                font-size: 14px;
            }

            &_label {
                color: black;
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 5px;
                display: flex;
                justify-content: flex-start;
            }

            &_btn {
                &--delete {
                    border: none;
                    color: white;
                    padding: 8px 14px;
                    border-radius: 3px;
                    transition: all 0.2s linear;
                    background-color: gray;
                    opacity: 0.7;

                    &.uploaded {
                        cursor: pointer;

                        &:hover {
                            opacity: 1;
                        }
                    }

                    &.empty {
                        cursor: not-allowed;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .specialist__service {
            display: flex;
            align-items: center;
            margin-top: 20px;

            .btn.btn--red {
                margin-right: 8px;
            }
        }
    }
}
