@import "../../assets/styles/main";

.btn {
    transition: all 0.2s linear;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    outline: none;
    width: 170px;
    height: 48px;
    cursor: pointer;
    color: $white;
    border-radius: 4px;
    border: none;

    &--outlined {
        border: 1px solid $white;
        background: $main-green;
        @extend .btn;

        &:hover {
            font-size: 16px;
            color: $main-green;
            background: $white;
            border: 1px solid $dark_gray;
        }
    }
    &--green {
        @extend .btn;
        background: $main-green;

        &:hover {
            opacity: 0.7;
        }
    }

    &--red {
        @extend .btn;
        background: #eb5353;

        &:hover {
            opacity: 0.7;
        }
    }

    &--loading {
        opacity: 0.7;
        pointer-events: none;
        @extend .btn--green;
    }

    &--disabled {
        background-color: #99d9bc;
        pointer-events: none;
        cursor: not-allowed;
        @extend .btn;
    }
}
