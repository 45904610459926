.account-details {
    &__inner {
        margin: 0 auto 50px auto;
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__header {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 30px auto 50px auto;
        font-size: 30px;

        &_id {
            font-weight: 600;
            color: black;
        }
    }
}
