.account {
    &--deleted {
        padding: 6px 10px;
        color: white;
        background-color: red;
        border-radius: 5px;
    }

    &--banned {
        margin-left: 5px;
        padding: 6px 10px;
        color: white;
        background-color: black;
        border-radius: 5px;
    }

    &__services {
        display: flex;
        flex-direction: column;

        &--edit {
            color: white;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(233, 229, 0);
                color: black;
                cursor: pointer;
            }
        }

        &--block,
        &--unblock {
            color: white;
            margin-bottom: 5px;
            padding: 2px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;
            width: 135px;

            &:hover {
                background-color: rgb(20, 10, 10);
                cursor: pointer;
            }
        }

        &--unblock {
            &:hover {
                background-color: #33b378;
                cursor: pointer;
            }
        }

        &--delete,
        &--restore {
            color: white;
            padding: 2px 12px;
            margin-bottom: 5px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(214, 32, 32);
                cursor: pointer;
            }
        }

        &--restore {
            &:hover {
                background-color: #33b378;
            }
        }

        &--delete-hard {
            margin-bottom: 0;
        }

        &--edit-service {
            color: white;
            padding: 5px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: #dbcd00;

            &:hover {
                background-color: #fcec02;
                box-shadow: rgb(20, 10, 10, 0.8);
                cursor: pointer;
            }
        }
    }
}
