@import "../../../assets/styles/main";
.user-complaints {
    width: 100%;
    border-spacing: 1;
    border-collapse: collapse;
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto 20px 0;
    position: relative;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);

    &__header {
        &_item {
            height: 60px;
            background: $main-green;
            &_title {
                font-size: 20px;
                color: $white;
                line-height: 1.2;
                font-weight: 500;
            }
        }
    }

    &_item {
        width: 100%;
        color: $dark_gray;
        line-height: 1.4;
        font-size: 18px;
        font-weight: 500;

        &_text {
            max-width: 400px;
            padding: 16px 5px;
        }
        &_link {
            text-decoration: none;
            padding-top: 16px;
            padding-bottom: 16px;

            &_post {
                @extend .user-complaints_item_link;
                color: $neutral-black-a32;

                &:hover {
                    color: $dark_gray;
                }
            }
            &_creator {
                @extend .user-complaints_item_link;
                color: $dark_gray;

                &:hover {
                    color: $main-green;
                }
            }
            &_user {
                @extend .user-complaints_item_link;
                color: #eb1010;
                &:hover {
                    color: $main_red;
                }
            }
        }

        &__options {
            padding: 4px 0;
            .btn.btn--green {
                margin-right: 8px;
            }
        }
        &:hover {
            background-color: #e8e5f6;
        }

        &:nth-child(even) {
            background-color: #f7f5ff;

            &:hover {
                background-color: #e8e5f6;
            }
        }
    }
    &_no-exist {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: $white;
        margin-bottom: 20px;
        width: 100%;
        height: 500px;
        border-radius: 10px;
        &-image {
            padding: 0 0 16px;
        }
        &-text {
            @include text-size(normal, 500, 20px, 24px);

            color: $neutral-black-a32;
        }
    }
}
