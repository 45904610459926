.language-control {
    position: relative;
    width: 70px;
    margin: 0 auto;
    padding: 30px 0 10px 0;

    &__selected {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        border: 1px solid #33b378;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        padding: 4px 8px;
        transition: all 0.2s linear;

        &:hover {
            background: #33b378;

            .language-control__text {
                color: #fff;
            }

            .language-control__icon {
                path {
                    fill: #fff;
                }
            }
        }

        .active {
            transform: rotate(180deg);
        }
    }

    &__text {
        font-size: 15px;
        line-height: 22px;
        text-align: center;
        color: black;
        margin: 0 0 0 12px;
    }

    &__select {
        &-list {
            width: 100%;
            position: absolute;
            top: 36px;
            padding: 0;
            border-radius: 4px;
            border: 1px solid #33b378;
            width: 123px;

            &::before {
                content: "";
                display: block;
                position: absolute;
                top: -4px;
                width: 7px;
                height: 7px;
                border-top: 1px solid #33b378;
                background: #fff;
                z-index: 5;
                left: 12px;
                border-left: 1px solid #33b378;
                transform: rotate(45deg);
            }
        }

        &-item {
            background: #fff;
            padding: 8px;
            display: flex;
            z-index: 4;
            transition: all 0.2s linear;
            position: relative;
            box-sizing: border-box;

            &:first-child {
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            &:last-child {
                border-bottom-left-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: 0px;
                width: 107px;
                height: 1px;
                background: #dfdfdf;
            }

            &:last-child {
                &::after {
                    display: none;
                }
            }

            .language-control__text {
                text-align: center;
                margin-left: 8px;
                display: flex;
                justify-content: center;
            }

            cursor: pointer;
        }
    }
}
