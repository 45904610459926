.delete-form {
    padding: 20px;
    text-align: center;
    border-radius: 6px;
    width: 500px;

    &__title {
        font-size: 20px;
        margin: 0 0 20px;
        line-height: 30px;

        &-name {
            font-weight: 600;
            color: #33b378;
        }
    }

    &__btns {
        align-items: center;

        .btn.btn--red {
            margin-right: 8px;
        }
    }
}
