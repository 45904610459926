@import "../../assets/styles/main";
.form-container {
    width: 100%;
    margin-bottom: 20px;

    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    &:last-of-type {
        margin-bottom: 0;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    &_input {
        width: 100%;
        height: 54px;
        font-size: 14px;
        border-radius: 8px;
        padding: 10px 15px;
        box-sizing: border-box;
        border: 1px solid $neutral-light-grey-fdf;

        &.error {
            border: 1px solid $main_red;
            &:focus {
                outline: none;
                border: 1px solid $main_red;
            }
        }

        &.confirmed {
            border: 1px solid $main_green;
            &:focus {
                outline: none;
                border: 1px solid $main_green;
            }
        }

        &:focus {
            outline: none;
            border: 1px solid $main_green;
        }
    }

    &-error {
        left: 7px;
        bottom: -19px;
        font-size: 12px;
        color: $main_red;
        position: absolute;
    }

    &_label {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 5px;
        color: $neutral-black-a32;
        justify-content: flex-start;
    }
    &__phone {
        display: flex;
        align-items: center;
        &-code {
            margin-right: 4px;
            width: 20%;
            height: 54px;
            font-size: 14px;
            border-radius: 8px;
            padding: 10px 15px;
            box-sizing: border-box;
            border: 1px solid $neutral-light-grey-fdf;
            outline: 0;
            &:focus {
                outline: none;
                border: 1px solid $main_green;
            }
            &.error {
                border: 1px solid $main_red;
                &:focus {
                    outline: none;
                    border: 1px solid $main_red;
                }
            }
        }
        &-number {
            width: 80%;
            height: 54px;
            font-size: 14px;
            border-radius: 8px;
            padding: 10px 15px;
            box-sizing: border-box;
            border: 1px solid $neutral-light-grey-fdf;
            outline: 0;
            &:focus {
                outline: none;
                border: 1px solid $main_green;
            }
            &.error {
                border: 1px solid $main_red;
                &:focus {
                    outline: none;
                    border: 1px solid $main_red;
                }
            }
        }
    }

    &__textarea {
        width: 300px;
        height: 120px;
        border-radius: 4px;
        border: 1px solid gray;
        padding: 10px 15px;
        margin: 0 0 8px 0;
        font-size: 14px;
        resize: vertical;

        &--error {
            outline: 0;
            width: 300px;
            height: 120px;
            resize: vertical;

            border-radius: 4px;
            border: 1px solid red;
            padding: 10px 15px;
            margin: 0 0 8px 0;
            font-size: 14px;
        }
    }
}
