@import "../../assets/styles/main";

.document {
    margin-bottom: 20px;

    &__content {
        height: 100%;
        margin: 0 5px;
        padding: 90px 0;
        overflow: hidden;
        border-left: 1px solid $main_green;
        border-right: 1px solid $main_green;
        border-bottom: 1px solid $main_green;
    }

    &__photo {
        max-height: 350px;
        max-width: 500px;
        margin: 0 auto;
        transition: 0.2s linear;
    }

    &__service {
        display: flex;
        justify-content: space-between;
        padding: 0 5px;

        &--rotate {
            width: 140px;
            padding: 8px 20px;
            border: 1px solid $dark_green;
            background-color: transparent;
            transition: 0.2s linear;

            &:hover {
                color: $white;
                cursor: pointer;
                background-color: $main_green;
            }
        }

        &--zoomin {
            width: 140px;
            padding: 8px 20px;
            border: 1px solid $dark_green;
            background-color: transparent;
            transition: 0.2s linear;

            &:hover {
                color: $white;
                cursor: pointer;
                background-color: $main_green;
            }
        }

        &--zoomout {
            width: 140px;
            padding: 8px 20px;
            border: 1px solid $dark_green;
            background-color: transparent;
            transition: 0.2s linear;

            &:hover {
                color: $white;
                cursor: pointer;
                background-color: $main_green;
            }
        }

        &--reset {
            width: 140px;
            padding: 8px 20px;
            border: 1px solid $dark_green;
            background-color: transparent;
            transition: 0.2s linear;

            &:hover {
                color: $white;
                cursor: pointer;
                background-color: $light_gray;
            }
        }
    }
}
