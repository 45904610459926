@import "../../../assets/styles/main";
.chat_body_message_own {
    position: relative;
    right: 0;
    float: right;
    display: grid;
    grid-template-areas: "text info";
    justify-content: end;
    align-self: flex-end;
    margin-bottom: 8px;
    background: $main_green;
    padding: 4px 4px 2px 8px;
    border-radius: 6px 0 6px 6px;

    &.--with-image {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &-same {
        position: relative;
        right: 0;
        float: right;
        display: grid;
        grid-template-areas: "text info";
        justify-content: end;
        align-self: flex-end;
        margin-bottom: 8px;
        background: $main_green;
        padding: 4px 4px 2px 8px;
        border-radius: 6px;

        &.--with-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &:after {
            display: none;
        }
    }

    &:after {
        right: -7px;
        top: -2px;
        position: absolute;
        content: url("../../../assets/images/chat/top_vector_tip.svg");
    }

    &_text {
        grid-area: text;
        max-width: 450px;
        color: $white;
        margin-right: 8px;
        margin-bottom: 15px;
        word-wrap: break-word;
        text-align: left;
        @include text-size(normal, normal, 15px, 18px);

        @include for-size(tablet-phone) {
            @include text-size(normal, normal, 13px, 16px);
            max-width: 210px;
        }
    }

    &_image {
        grid-area: image;
        padding: 4px 4px 2px 0;
        position: relative;

        img,
        video {
            max-width: 220px;
            max-height: 138px;
            object-fit: cover;
            border-radius: 4px;
            cursor: pointer;

            @include for-size(phone) {
                max-width: 150px;
                max-height: 88px;
            }
        }

        &-video {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 37px;
            height: 17px;
            background: #272a32;
            border-radius: 6px;
            left: 8px;
            top: 8px;
            color: #fff;
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 22px;
        }
    }

    &_info {
        grid-area: info;
        display: flex;
        align-items: flex-end;

        &_time {
            color: $white;
            margin-right: 4px;
            @include text-size(normal, normal, 11px, 13px);

            @include for-size(tablet-phone) {
                @include text-size(normal, normal, 10px, 13px);
            }
        }
    }
}

.chat_body_message_companion {
    display: flex;
    max-width: 480px;
    margin-bottom: 8px;

    &-same {
        display: flex;
        max-width: 480px;
        margin-bottom: 8px;

        @include for-size(tablet-phone) {
            max-width: 210px;
        }

        .chat_body_message_companion_avatar {
            display: none;
        }

        .chat_body_message_companion_body {
            margin-left: 42px;
            border-radius: 6px;

            &:before {
                display: none;
            }
        }
    }

    &_avatar {
        margin-right: 10px;

        @include for-size(tablet-phone) {
            margin-right: 8px;
        }

        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
        }

        svg {
            width: 32px;
            height: 32px;
            object-fit: cover;
        }
    }

    &_body {
        position: relative;
        display: grid;
        grid-template-areas: "info status";
        justify-content: end;
        align-self: flex-end;
        background-color: $neutral-white-1f1;
        padding: 4px 5px 6px 4px;
        border-radius: 0 6px 6px 6px;

        &.--with-image {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &::before {
            left: -7px;
            top: -2px;
            position: absolute;
            content: url("../../../assets/images/chat/top_vector_tip_gray.svg");
        }
        &_info {
            grid-area: info;
        }

        &_name {
            color: $neutral-black-c2e;
            margin-bottom: 4px;
            @include text-size(normal, bold, 15px, 18px);

            @include for-size(tablet-phone) {
                @include text-size(normal, bold, 13px, 16px);
            }
        }

        &_description {
            color: $neutral-black-c2e;
            margin-bottom: 10px;
            max-width: 400px;
            word-wrap: break-word;
            text-align: left;
            @include text-size(normal, normal, 15px, 18px);

            @include for-size(tablet-phone) {
                @include text-size(normal, normal, 13px, 16px);
            }

            &_price {
                @include text-size(normal, bold, 15px, 18px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, bold, 13px, 16px);
                }
            }
        }

        &_image {
            padding: 4px 4px 2px 0;
            position: relative;

            img,
            video {
                max-width: 220px;
                max-height: 138px;
                border-radius: 4px;
                cursor: pointer;

                @include for-size(phone) {
                    max-width: 150px;
                    max-height: 88px;
                }
            }

            &-video {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                width: 37px;
                height: 17px;
                background: #272a32;
                border-radius: 6px;
                left: 8px;
                top: 8px;
                color: #fff;
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 22px;
            }
        }

        &__status {
            grid-area: status;
            margin-left: 5px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            &_time {
                color: $gray_68;
                margin-right: 4px;
                @include text-size(normal, normal, 11px, 13px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, normal, 10px, 13px);
                }
            }
        }
    }
}

.chat_body_date {
    display: flex;
    justify-content: center;
    color: $neutral-grey-282;
    @include text-size(normal, normal, 15px, 18px);

    @include for-size(tablet-phone) {
        @include text-size(normal, normal, 13px, 16px);
    }

    &:first-child {
        margin: 0 0 24px 0;

        @include for-size(tablet-phone) {
            margin: 0 0 15px 0;
        }
    }
}

.chat_body_message_system {
    display: flex;
    max-width: 480px;
    margin-bottom: 8px;

    @include for-size(tablet-phone) {
        max-width: 210px;
    }

    &_avatar {
        margin-right: 10px;

        @include for-size(tablet-phone) {
            margin-right: 8px;
        }

        svg {
            width: 32px;
            height: 32px;
        }

        &.cancelled {
            svg {
                rect {
                    stroke: #ffebea;
                    fill: red;
                }
            }
        }
    }

    &_body {
        display: flex;
        border-radius: 0 6px 6px 6px;
        background-color: $white_background_def;
        padding: 4px 5px 6px 4px;
        position: relative;

        &.cancelled {
            background-color: #ffebea;

            &::before {
                content: url("../../../assets/images/chat/top_vector_tip_red.svg");
            }
        }

        @include for-size(tablet-phone) {
            max-width: 210px;
        }

        &::before {
            left: -7px;
            top: -2px;
            position: absolute;
            content: url("../../../assets/images/chat/top_vector_tip_green.svg");
        }

        &_title {
            color: $neutral-black-c2e;
            margin-bottom: 4px;
            @include text-size(normal, 700, 15px, 18px);

            @include for-size(tablet-phone) {
                @include text-size(normal, 700, 13px, 16px);
            }
        }

        &_name {
            max-width: 400px;
            word-wrap: break-word;
            color: $neutral-black-c2e;
            margin-bottom: 4px;
            @include text-size(normal, 400, 15px, 18px);

            @include for-size(tablet-phone) {
                @include text-size(normal, 400, 13px, 16px);
                max-width: 210px;
            }
        }

        &_description {
            color: $neutral-black-c2e;
            margin-bottom: 10px;
            @include text-size(normal, normal, 15px, 18px);

            @include for-size(tablet-phone) {
                @include text-size(normal, normal, 13px, 16px);
            }

            &_marked {
                @include text-size(normal, bold, 15px, 18px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, bold, 13px, 16px);
                }
            }

            &_price {
                @include text-size(normal, bold, 15px, 18px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, bold, 13px, 16px);
                }
            }
        }

        &__service {
            display: flex;
            align-items: center;

            @include for-size(tablet-phone) {
                flex-direction: column;
            }

            &_cancel {
                text-align: center;
                padding: 6px 0px;
                border: none;
                border: none;
                background-color: $main_green;
                border-radius: 8px;
                @include text-size(normal, normal, 15px, 18px);
                color: $white;
                outline: none;
                width: 150px;
                margin-right: 7px;
                cursor: pointer;
                transition: all 0.2s linear;

                @include for-size(tablet-phone) {
                    @include text-size(normal, normal, 13px, 16px);
                    margin: 0 0 7px 0;
                }

                &:hover {
                    opacity: 0.7;
                }

                &[disabled] {
                    cursor: not-allowed;
                    background-color: $neutral-light-grey-fdf;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &_approve {
                text-align: center;
                padding: 6px 0px;
                border: none;
                border: none;
                width: 150px;
                background-color: $main_green;
                border-radius: 8px;
                @include text-size(normal, normal, 15px, 18px);
                color: $white;
                outline: none;
                cursor: pointer;
                transition: all 0.2s linear;

                @include for-size(tablet-phone) {
                    @include text-size(normal, normal, 13px, 16px);
                }

                &:hover {
                    opacity: 0.7;
                }

                &[disabled] {
                    cursor: not-allowed;
                    background-color: $neutral-light-grey-fdf;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &_confirm {
                text-align: center;
                padding: 6px 0px;
                border: none;
                border: none;
                width: 100%;
                background-color: $main_green;
                border-radius: 8px;
                @include text-size(normal, normal, 15px, 18px);
                color: $white;
                outline: none;
                cursor: pointer;
                transition: all 0.2s linear;

                @include for-size(tablet-phone) {
                    @include text-size(normal, normal, 13px, 16px);
                }

                &:hover {
                    opacity: 0.7;
                }

                &[disabled] {
                    cursor: not-allowed;
                    background-color: $neutral-light-grey-fdf;
                }
            }
        }

        &__status {
            margin-left: 8px;
            display: flex;
            justify-content: center;
            align-items: flex-end;

            @include for-size(tablet-phone) {
                margin-left: 4px;
            }

            &_time {
                color: $gray_68;
                margin-right: 4px;
                @include text-size(normal, normal, 11px, 13px);

                @include for-size(tablet-phone) {
                    @include text-size(normal, normal, 10px, 13px);
                }
            }
        }
    }

    &_body-cancelled {
        background-color: #ffebea;
    }
}
