@import "../../assets/styles/main";

.upload_photo {
    width: 100%;

    &_error {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: red;
    }

    &__initial {
        position: relative;

        &_image {
            height: 100%;
            width: 100%;
            border-radius: 50%;
            max-width: 100%;
            background-size: cover;
            object-fit: cover;

            &:hover {
                cursor: pointer;
            }

            &.rectangle {
                border-radius: 0;
            }
        }

        &_circle {
            position: relative;
            display: flex;
            margin: 0 auto;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 150px;
            height: 150px;
            background: #828282;
            box-sizing: border-box;
            border-radius: 50%;
            cursor: pointer;

            &.rectangle {
                border-radius: 0;
                width: 332px;
            }
        }

        &_plus {
            position: absolute;
            bottom: -10px;
            right: -8px;
            cursor: pointer;
            svg {
                width: 44px;
                height: 44px;
            }
        }

        &_input {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 0;
            outline: 0;
            opacity: 0;
            cursor: pointer;
        }
    }
}
