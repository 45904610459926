.clarification {
    &__options {
        display: flex;
        flex-direction: column;

        &--edit {
            color: white;
            margin-bottom: 10px;
            padding: 5px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(233, 229, 0);
                color: black;
                cursor: pointer;
            }
        }

        &--delete {
            color: white;
            padding: 5px 12px;
            border: none;
            border-radius: 5px;
            transition: 0.2s linear;
            background-color: gray;

            &:hover {
                background-color: rgb(214, 32, 32);
                cursor: pointer;
            }
        }
    }
}
