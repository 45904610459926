@import "../../../assets/styles/main";
.types-complaints {
    width: 750px;
    margin: 0 auto;
    border-spacing: 1;
    border-collapse: collapse;
    background: $white;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    box-shadow: 0 0 40px 0 rgb(0 0 0 / 15%);

    &__header {
        &_item {
            height: 60px;
            background: $main-green;
            &_title {
                width: 50%;
                font-size: 20px;
                color: $white;
                line-height: 1.2;
                font-weight: 500;
            }
        }
    }

    &__content {
        &_item {
            width: 100%;
            color: $dark_gray;
            line-height: 1.4;
            font-size: 18px;
            font-weight: 500;

            &_text {
                padding-top: 16px;
                padding-bottom: 16px;
                &:first-of-type {
                    padding-left: 100px;
                    text-align: left;
                }
            }

            &:hover {
                background-color: #e8e5f6;
            }

            &:nth-child(even) {
                background-color: #f7f5ff;

                &:hover {
                    background-color: #e8e5f6;
                }
            }
        }
    }
    &__options {
        padding: 4px 0;
        .btn.btn--green {
            margin-right: 8px;
        }
    }
}
