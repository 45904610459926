$main_background: #e5e5e5;
$main_green: #33b378;
$main_red: #ff3b30;
$dark_green: #006600;
$dark_gray: #556267;
$light_gray: #ababab;
$gray_68: #666668;
$white: #ffffff;
$white_background_def: #def7eb;
$neutral-light-grey-fdf: #dfdfdf;
$neutral-grey-282: #828282;
$neutral-grey-343: #434343;
$neutral-black-a32: #272a32;
$neutral-white-1f1: #f1f1f1;

$neutral-black-c2e: #2c2c2e;
