.support__chat-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 384px;
    border-radius: 10px;
    width: 100%;

    &--img {
        width: 87px;
        height: 120px;
        padding: 0 0 16px 0;
    }

    &--title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #272a32;
    }
}
